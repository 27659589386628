.thinkTheOpposite {
  position: relative;
  width: 1507.63px;
  height: 154.11px;
}
.thinkTheOpposite1 {
  position: relative;
  font-weight: 900;
  display: none;
}
.herocontent {
  width: 1440px;
  height: 376px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
  box-sizing: border-box;
  gap: 30px;

  background-image: url("/public/dividertitle@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.header {
  align-self: stretch;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.clmn {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.clmn1 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cardsframe {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}
.logoIcon {
  position: relative;
  width: 284px;
  height: 41.78px;
}
.logowyckx {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 18.664562225341797px;
}
.cardsframe1 {
  align-self: stretch;
  border-radius: 30px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 100px;
  box-sizing: border-box;
  background-image: url("/public/cardsframe@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.oldNew {
  position: relative;
  width: 740.3px;
  height: 117.15px;
}
.dividertitle {
  align-self: stretch;
  height: 288px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
  box-sizing: border-box;
  gap: 30px;
  background-image: url("/public/dividertitle@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.rightclmn {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.cardsframe4 {
  align-self: stretch;
  border-radius: 30px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 0px 0px;
  box-sizing: border-box;
  background-image: url("/public/cardsframe1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.cards {
  width: 1000px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 10px 150px;
  box-sizing: border-box;
  gap: 20px;
}
.solution {
  position: relative;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 165px;
  color: #000;
  font-family: "Work Sans";
}

@media screen and (max-width: 420px) {
  .thinkTheOpposite {
    width: 430px;
  }

  .herocontent {
    height: 400px;
    padding-top: 0px;
    padding-bottom: 0px;
    box-sizing: border-box;
  }

  .clmn {
    flex: unset;
    align-self: stretch;
  }

  .clmn1 {
    flex: unset;
    align-self: stretch;
  }

  .cardsframe {
    flex-direction: column;
  }

  .oldNew {
    width: 380px;
  }

  .rightclmn {
    flex: unset;
    align-self: stretch;
  }

  .cardsframe4 {
    flex-direction: column;
  }

  .cards {
    align-self: stretch;
    width: auto;
  }
}
