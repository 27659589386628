.ourPlatform {
  align-self: stretch;
  position: relative;
}
.theUpcomingGeneration {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  line-height: 34px;
  font-weight: 300;
  font-family: "Roboto Condensed";
}
.herocontent {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 100px;
  gap: 30px;
}
.herovisual {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
}
.herocontainer {
  flex: 1;
  width: 1440px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.ribbonspacerIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 11px;
  flex-shrink: 0;
  object-fit: cover;
}
.header {
  align-self: stretch;
  height: 95vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url("/public/header1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.clmn {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.clmn1 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cardsframe {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}
.cardsframeIcon {
  align-self: stretch;
  border-radius: 30px;
  max-width: 100%;
  overflow: hidden;
  height: 500px;
  flex-shrink: 0;
  object-fit: cover;
}
.cards {
  width: 1000px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 10px;
  box-sizing: border-box;
  gap: 20px;
}
.platform {
  position: relative;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 50px;
  color: #fff;
  font-family: "Work Sans";
}

@media screen and (max-width: 420px) {
  .herocontent {
    padding-left: 20px;
    padding-right: 40px;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }

  .herovisual {
    flex: unset;
    align-self: stretch;
  }

  .herocontainer {
    align-self: stretch;
    width: auto;
    flex-direction: column;
  }

  .clmn {
    flex: unset;
    align-self: stretch;
  }

  .clmn1 {
    flex: unset;
    align-self: stretch;
  }

  .cardsframe {
    flex-direction: column;
  }

  .cards {
    align-self: stretch;
    width: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
  }
}
