.logoIcon {
  position: relative;
  width: 456.48px;
  height: 34px;
}
.logowyckx {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 30px;
}
.herocaption {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  padding: 0 30px;
}
.herotitles {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.k {
  position: relative;
  text-transform: uppercase;
}
.tokenSupply {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
}
.clmn01 {
  align-self: stretch;
  flex: 1;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 27px 0px;
  gap: 10px;
}
.clmn03 {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 27px 0px;
  gap: 10px;
}
.metricsbox {
  border-radius: 30px;
  width: 500px;
  height: 120px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 50px;
  font-family: "Roboto Condensed";
}
.herocontent {
  flex: 1;
  width: 700px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  gap: 50px;
  margin-top: 60px;
}
.herocontainer {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ribbonspacerIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 11px;
  flex-shrink: 0;
  object-fit: cover;
}
.header {
  align-self: stretch;
  height: 90vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-image: url("/public/header2@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.clmn {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.clmn1 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cardsframe {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}
.logoIcon1 {
  position: relative;
  width: 284px;
  height: 41.78px;
}
.logowyckx1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 18.664562225341797px;
}
.cardsframe1 {
  align-self: stretch;
  border-radius: 30px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 100px;
  box-sizing: border-box;
  background-image: url("/public/cardsframe6@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.cardsframeIcon {
  align-self: stretch;
  border-radius: 30px;
  max-width: 100%;
  overflow: hidden;
  height: 500px;
  flex-shrink: 0;
  object-fit: cover;
}
.cards {
  width: 1000px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 10px;
  box-sizing: border-box;
  gap: 20px;
}
.home {
  position: relative;
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-family: "Work Sans";
}

@media screen and (max-width: 420px) {
  .header {
    height: 95vh;
    background-position: top right;
  }

  .logoIcon {
    width: 300px;
  }

  .herocaption {
    font-size: 18px;
    line-height: 24px;
  }

  .k {
    font-size: 34px;
  }

  .tokenSupply {
    font-size: 10px;
  }

  .clmn01 {
    gap: 0px;
  }

  .clmn03 {
    gap: 0px;
  }

  .metricsbox {
    align-self: stretch;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .herocontent {
    align-self: stretch;
    width: auto;
  }

  .clmn {
    flex: unset;
    align-self: stretch;
  }

  .clmn1 {
    flex: unset;
    align-self: stretch;
  }

  .cardsframe {
    flex-direction: column;
  }

  .cards {
    align-self: stretch;
    width: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
  }
}
