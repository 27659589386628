.weBelieveThat {
  align-self: stretch;
  position: relative;
  line-height: 28px;
}
.clmn02 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
}
.ourSolution {
  position: relative;
  text-transform: uppercase;
}
.clmn01 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 35px 30px;
  gap: 20px;
  text-align: center;
  font-size: 14px;
}
.herocontent {
  width: 800px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 40px;
  box-sizing: border-box;
  gap: 50px;
}
.footer {
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 0px;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  color: #000;
  font-family: "Roboto Condensed";
  align-self: stretch;
}

button {
  padding: 0;
  color: #000;
}

@media screen and (max-width: 420px) {
  .clmn02 {
    padding: 10px;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }

  .clmn01 {
    padding: 10px;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }

  .herocontent {
    flex: 1;
    flex-direction: column;
    gap: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
