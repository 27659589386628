.icon {
  position: relative;
  width: 61.89px;
  height: 30px;
}
.communityEconomics {
  align-self: stretch;
  position: relative;
  font-family: "Work Sans";
}
.inTheEra {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
}
.buttontitle {
  position: relative;
  text-transform: uppercase;
}
.button {
  border-radius: 30px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  text-align: center;
  font-size: 18px;
  color: #000;
}
.card {
  border-radius: 30px;
  background-color: #111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 130px 30px 30px;
  box-sizing: border-box;
  gap: 30px;
  text-align: left;
  font-size: 24px;
  color: #fff;
  font-family: "Roboto Condensed";
  align-self: stretch;
}
