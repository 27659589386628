.ribbonspacerIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 11px;
  flex-shrink: 0;
  object-fit: cover;
}
.heresToThe {
  align-self: stretch;
  position: relative;
  line-height: 54px;
  font-weight: 300;
}
.quotecontentChild {
  position: relative;
  border-bottom: 3px solid #fff;
  box-sizing: border-box;
  width: 180px;
  height: 15px;
  overflow: hidden;
  flex-shrink: 0;
}
.inReferenceTo {
  position: relative;
  font-size: 18px;
  line-height: 54px;
}
.logoIcon {
  position: relative;
  width: 284px;
  height: 41.78px;
}
.logowyckx {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 18.664562225341797px;
}
.weAreConvinced {
  align-self: stretch;
  position: relative;
  line-height: 34px;
  font-weight: 300;
}
.quotecontent1 {
  width: 700px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
  box-sizing: border-box;
  gap: 30px;
  font-size: 24px;
}
.quotecontent {
  width: 700px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
  box-sizing: border-box;
  gap: 30px;
}
.quote {
  background-color: #111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 40px;
  color: #fff;
  font-family: "Roboto Condensed";
  align-self: stretch;
}

@media screen and (max-width: 420px) {
  .logoIcon {
    width: 200px;
  }

  .quotecontent1 {
    align-self: stretch;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }

  .quotecontent {
    align-self: stretch;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
