.icon1 {
  position: relative;
  width: 61.89px;
  height: 30px;
}
.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 30px;
}
.ourSolution {
  position: relative;
  text-transform: uppercase;
}
.menu {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;
}
.vectorIcon {
  position: relative;
  width: 30px;
  height: 30px;
}
.socialicons {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 50px;
}
.navigationcontainer {
  position: fixed;
  left: 0; top: 0;
  z-index: 1000;
  width: 100vw;
  margin: 0;
  height: 70px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
  gap: 10px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  font-family: "Work Sans";
  align-self: stretch;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.menuitemsolution {
  padding: 10px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-family: "Work Sans";
  font-size: 12px;
}

@media screen and (max-width: 420px) {
  .icon {
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
  }

  .menu {
    display: none;
  }

  .socialicons {
    padding-left: 0px;
    padding-right: 00px;
    box-sizing: border-box;
  }

  .navigationcontainer {
    height: 60px;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    box-sizing: border-box;
  }
}
