.old1 {
  position: relative;
}
.labelold {
  border-radius: 50px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 18px;
}
.peopleUsedTo {
  align-self: stretch;
  position: relative;
  font-size: 34px;
  color: #fff;
}
.old {
  align-self: stretch;
  background-color: #111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 100px 30px 30px;
  gap: 20px;
}
.label {
  border-radius: 50px;
  background-color: #111;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 18px;
}
.labelnew {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.communitiesOwn {
  align-self: stretch;
  position: relative;
  font-size: 34px;
  color: #000;
}
.helloWorld {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 28px;
  font-family: "Roboto Condensed";
  color: #666;
}
.new {
  align-self: stretch;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 30px 60px;
  gap: 20px;
  color: #fff;
}
.systemchangecard {
  border-radius: 30px;
  background-color: #fff;
  border: 2px solid #ccc;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #000;
  font-family: "Work Sans";
  align-self: stretch;
}
